<template>
    <div class="board-body" style="margin-top: 16px">
        <div ref="container"></div>
    </div>
</template>

<script>
import ApiService from '@/assets/js/api.service';
import {onMounted, ref} from 'vue';
import {isSuccess} from '@/assets/js/common.utils';
import {embedDashboard} from 'amazon-quicksight-embedding-sdk';
import {hideLoading} from '@/assets/js/common.alert';

export default {
    name: 'BoardQuickSight',
    props: {
        bno: Number,
    },
    setup(props) {
        const container = ref(null);

        const getQuickSight = () => {
            ApiService.post(`/v1/aws/quicksight/boards/${props.bno}`, {})
                .then(res => {
                    if (isSuccess(res) && res.embeddedURL) {
                        const options = {
                            url: res.embeddedURL,
                            container: container.value,
                            scrolling: 'no',
                            height: '700px',
                            width: '100%',
                            footerPaddingEnabled: true,
                        };
                        embedDashboard(options);
                    }
                    hideLoading();
                })
                .catch(e => {
                    console.error(e);
                    hideLoading();
                });
        };

        onMounted(() => {
            if (props.bno > 0) {
                getQuickSight();
            }
        });

        return {
            container,
        };
    },
};
</script>
