<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <div class="header-row">
                    <div class="header-actions">
                        <button class="btn-action btn-action-back" @click="goBack($event, divisions.listPath)">
                            <i class="icon-arrow"></i>
                            <strong class="text">이전</strong>
                        </button>
                    </div>

                    <CommonBreadcrumb :show-title="false" />
                </div>
                <h2 class="page-title">&nbsp;</h2>
            </div>
        </div>
        <!-- page-body -->
        <div v-if="isReady" class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-view-container">
                    <header class="board-header">
                        <h3 class="board-title">{{ params.boardNm }}</h3>
                        <div class="board-meta">{{ params.boardSubNm }}</div>
                    </header>
                    <div class="board-body swcc-editor-container" v-html="params.boardContents"></div>
                    <BoardQuickSight v-if="divisions.key === 'visualization'" :bno="params.bno" />
                    <BoardAttachment :attachments="attachments" />
                </div>
            </div>
            <!-- //page-contents -->
        </div>

        <div v-if="session.name !== null && session.manager" class="page-bottom bottom-fixed" style="bottom: 16px">
            <div class="bottom-board-upload bottom-component">
                <button class="btn btn-primary" @click="goEdit(divisions.listName, 'bno', bno)"><span class="text">글수정 </span></button>
            </div>
        </div>
    </main>
</template>

<script>
import {computed, reactive, ref} from 'vue';
import {useRoute} from 'vue-router';
import {collectionCheck, getItem, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import ApiService from '@/assets/js/api.service';
import {domainCode} from '@/assets/js/domain.code';
import {goBack, goEdit} from '@/assets/js/modules/common/module';
import {getAuthSession} from '@/assets/js/modules/auth/module';
import BoardAttachment from '@/components/board/BoardAttachment';
import BoardQuickSight from '@/components/board/BoardQuickSight';

export default {
    name: 'BoardView',
    components: {BoardQuickSight, BoardAttachment, CommonBreadcrumb},
    setup: function () {
        const route = useRoute();

        const isReady = ref(false);
        const session = computed(getAuthSession);
        const bno = computed(() => (route.params.bno ? parseInt(route.params.bno) : 0));
        const attachments = ref([]);
        const params = reactive({
            bno: 0,
            boardNm: '',
            boardSubNm: '',
            boardContents: '',
            boardTypeDcd: '',
            createDt: '',
        });

        const divisions = reactive({
            key: computed(() => route.name.replace('View', '').toLowerCase()),
            listName: computed(() => `${route.name.replace('View', '')}`),
            // listName: computed(() => route.name.replace('Edit', '')),
            listPath: computed(() => {
                if (route.name.includes('Consulting')) {
                    return '/hub/consulting';
                } else if (route.name.includes('Conversion')) {
                    return '/hub/conversion';
                } else if (route.name.includes('Visualization')) {
                    return '/hub/visualization';
                } else if (route.name.includes('Analytics')) {
                    return '/hub/analytics';
                }
                return '/main';
            }),
            title: computed(() => {
                if (route.name.includes('Consulting')) {
                    return 'AI ・ 데이터 컨설팅';
                } else if (route.name.includes('Conversion')) {
                    return 'SW 융합사례';
                } else if (route.name.includes('Visualization')) {
                    return '시각화 분석사례';
                } else if (route.name.includes('Analytics')) {
                    return '애널리틱 분석사례';
                }
                return '';
            }),
            boardTypeDcd: computed(() => {
                if (route.name.includes('Consulting')) {
                    return domainCode.BOARD_TYPE_CONSULTING;
                } else if (route.name.includes('Conversion')) {
                    return domainCode.BOARD_TYPE_CONVERSION;
                } else if (route.name.includes('Visualization')) {
                    return domainCode.BOARD_TYPE_VISUALIZATION;
                } else if (route.name.includes('Analytics')) {
                    return domainCode.BOARD_TYPE_ANALYTICS;
                }
                return null;
            }),
        });

        const getBoard = () => {
            if (bno.value > 0) {
                ApiService.get('/v1/boards', bno.value).then(res => {
                    if (lengthCheck(res)) {
                        const item = getItem(res);
                        if (item.boardTypeDcd === divisions.boardTypeDcd) {
                            setParams(params, item);
                            if (collectionCheck(item.boardFiles, 'fileNo')) {
                                attachments.value.push(...item.boardFiles);
                            }
                        }
                    }
                    isReady.value = true;
                });
            }
        };

        getBoard();

        return {
            bno,
            session,
            isReady,
            divisions,
            params,
            attachments,
            goBack,
            timestampToDateFormat,
            goEdit,
        };
    },
};
</script>
