<template>
    <div v-if="attachments.length > 0" class="board-footer">
        <div class="board-files">
            <h4 class="title">첨부파일</h4>
            <div class="filelist">
                <a v-for="(item, index) in attachments" href="javascript:" class="file" :key="index" @click="downloadFile(index)">
                    <i class="icon-download"></i><span class="file-text">{{ item.fileNm }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '@/assets/js/api.service';

export default {
    name: 'BoardAttachment',
    props: {
        attachments: Array,
    },
    setup(props) {
        const downloadFile = index => {
            console.log(props.attachments[index]);
            ApiService.download(`/v1/boards/files/${props.attachments[index].fileNo}`, {}, props.attachments[index].fileNm);
        };

        return {
            downloadFile,
        };
    },
};
</script>
